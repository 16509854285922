<template>
  <div class="w-full">
    
<FormKitProvider :config="__formkitConfig">
<div v-if="errorMessage" class="relative h-12">
      <AuthErrorMessage :error="errorMessage" />
    </div>

    <FormKit v-model="formData" type="form" :actions="false" @submit="submit">
      <FormKitMessages class="text-system-error mb-4" />

      <div class="space-y-4">
        <FormKit
          name="email"
          type="email"
          :placeholder="t('emailAddress')"
          validation="required|email"
          :validation-messages="{
            required: t('requiredEmail'),
          }"
        />

        <FormKit
          type="password"
          name="password"
          validation="required"
          :placeholder="t('password')"
          :validation-messages="{
            required: t('requiredPassword'),
          }"
        />

        <div class="mt-4 flex flex-col space-y-1">
          <Button :text="t('login')" :type="SUBMIT" :theme="PRIMARY" />

          <Button
            :text="t('forgotPassword')"
            :theme="TRANSPARENT"
            class="underline"
            @click="changeState(RESET_PASSWORD)"
          />
        </div>
      </div>
    </FormKit>
</FormKitProvider>

  </div>
</template>

<script setup lang="ts">
import { FormKitProvider } from "@formkit/vue";
import __formkitConfig from "virtual:formkit-config";
import type { AuthTokenPayload } from '#gql/default'

const emit = defineEmits(['close'])

const { t } = useI18n()
const { setAuthTokenCookies } = useAccountCookies()
const { changeState } = useAuthState()
const { locale } = useI18n()

const { PRIMARY, TRANSPARENT } = BUTTON_THEME
const { SUBMIT } = BUTTON_TYPE
const { RESET_PASSWORD } = USER_AUTH_STATE

const formData = ref()
const errorMessage = ref()

async function submit() {
  useGqlHeaders({ 'Accept-Language': locale.value })

  const { data, error } = await useAsyncGql('AccountLogin', {
    email: formData.value?.email,
    password: formData.value.password,
  })

  if (data.value?.login?.accessToken && data.value?.login?.refreshToken) {
    setAuthTokenCookies(data.value?.login as AuthTokenPayload)
    emit('close')
  }

  if (error.value) {
    errorMessage.value =
      error.value?.cause?.gqlErrors?.[0]?.extensions?.message ??
      error.value?.cause?.gqlErrors?.[0]?.message
  }
}

defineOptions({
  name: 'AuthLogin',
})
</script>

<i18n>
de:
  login: "Anmelden"
  forgotPassword: "Passwort vergessen?"
  emailAddress: "E-Mail-Adresse"
  password: "Passwort"
  requiredEmail: 'Bitte E-Mail-Adresse eingeben.'
  requiredPassword: 'Bitte Passwort eingeben.'
es:
  login: "Iniciar sesión"
  forgotPassword: "¿Olvidaste tu contraseña?"
  emailAddress: "Correo electrónico"
  password: "Contraseña"
  requiredEmail: 'Por favor, introduce tu dirección de correo electrónico.'
  requiredPassword: 'Por favor, introduce tu contraseña.'
</i18n>
